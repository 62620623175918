/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import LogoImg from "../images/AISTO-LOGO.png"
import FbImg from "../images/f_logo_RGB-Black_58.png"
import IgImg from "../images/instagram-loco-black.png"

import Header from "./header"
//import "bootstrap/dist/js/bootstrap.min.js";


if (typeof window !== `undefined`) {
  var $ = require('jquery');
  window.$ = $;
  require('bootstrap/js/dist/alert');
}

const Layout = ({ children, title }) => {

  return (
    <>
        <Header siteTitle="{data.site.siteMetadata.title}" />
        <div className="container-fluid">

          <nav className="navbar navbar-expand-lg flex-row pb-5" >
            <div className="navbar-brand d-flex w-50 mr-0">
              <img src={LogoImg} className="img-responsive w-50" alt="Aisto" style={{display: 'block'}}/>
            </div>
            <button className="navbar-toggler navbar-light" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse w-100" id="navbarSupportedContent">
              <ul className="navbar-nav mx-sm-auto">
                <li className="nav-item align-self-center">
                  <a className="nav-link" href="/" style={{color: title === 'Projektit' ? 'black' : 'grey', textDecoration: title === 'Projektit' ? 'underline': 'none' }} >projektit</a>
                </li>
                <li className="nav-item align-self-center">
                  <a className="nav-link" href="/contact" style={{color: title === 'Info' ? 'black' : 'grey', textDecoration: title === 'Info' ? 'underline': 'none' }}>info</a>
                </li>
              </ul>
              <div className="media-buttons">
                <a href="https://www.facebook.com/aisto.fi"> <img src={FbImg} className="img-responsive mx-2" alt="FB" style={{display: 'block'}} width="35em" height="35em"/> </a>
                <a href="https://instagram.com/aisto.fi"> <img src={IgImg} className="img-responsive mx-2" alt="IG" style={{display: 'block'}} width="35em" height="35em"/> </a>
              </div>
            </div>

          </nav>
          <main className="pb-5">{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired
}

export default Layout
